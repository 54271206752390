import "./video-modal.css";

import { useEffect, useRef, useState } from "react";

import { useVideoModal } from "../../hooks/use-video-modal";
import { videoModalDatas } from "../../data/data";

interface VideoDataProps {
    id: string;
    title: string;
    link: string;
}

export const VideoModal = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const modalBackdropRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const [data, setData] = useState<VideoDataProps>();

    const id = useVideoModal((state) => state.id);
    const isOpen = useVideoModal((state) => state.isOpen);
    const onClose = useVideoModal((state) => state.onClose);

    useEffect(() => {
        if (!id) {
            videoRef.current?.pause();
            setTimeout(() => {
                setData(undefined);
                modalRef.current?.style.setProperty('z-index', '-1060');
                modalBackdropRef.current?.style.setProperty('z-index', '-1040');
            }, 550);
            return;
        }

        const modalData = videoModalDatas.find((data) => data.id === id);
        if (!modalData) return;

        setData(modalData);
        modalRef.current?.style.setProperty('z-index', '1060');
        modalBackdropRef.current?.style.setProperty('z-index', '1040');
        setTimeout(() => {
            if (videoRef.current) {
                videoRef.current.volume = 0.25;
                videoRef.current.play();
            }
        }, 550);
    }, [id])

    return (
        <>
            <div
                ref={modalRef}
                className={`modal video ${isOpen && data ? "open" : ""}`}
            >
                <div className="modal-dialog">
                    <div
                        className="modal-content"
                    >
                        <span className="modal-close" onClick={onClose}></span>
                        <div className="modal-header">
                            {data?.title && (
                                <h3 dangerouslySetInnerHTML={{ __html: data?.title }} />
                            )}
                        </div>
                        <div className="modal-video">
                            {data?.link && (
                                <video
                                    controls
                                    controlsList="nodownload"
                                    ref={videoRef}
                                >
                                    <source src={data?.link} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref={modalBackdropRef}
                className={`modal-backdrop ${isOpen && data ? "open" : ""}`}
                onClick={onClose}
            />
        </>
    )
}