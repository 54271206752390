import "./modal.css";

import { useEffect, useRef, useState } from "react";

import { useModal } from "../../hooks/use-modal";
import { modalDatas } from "../../data/data";

interface DataProps {
    id: string;
    style: string;
    title: string;
    icon: string;
    icon_width?: number;
    list?: {
        title: string;
        text: string;
    }[];
    text?: string;
    link?: string;
}

export const Modal = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const modalBackdropRef = useRef<HTMLDivElement>(null);

    const [data, setData] = useState<DataProps>();

    const id = useModal((state) => state.id);
    const isOpen = useModal((state) => state.isOpen);
    const onClose = useModal((state) => state.onClose);

    useEffect(() => {
        if (!id) {
            setTimeout(() => {
                setData(undefined);
                modalRef.current?.style.setProperty('z-index', '-1060');
                modalBackdropRef.current?.style.setProperty('z-index', '-1040');
            }, 550);
            return;
        }

        const modalData = modalDatas.find((data) => data.id === id);
        if (!modalData) return;

        setData(modalData);
        modalRef.current?.style.setProperty('z-index', '1060');
        modalBackdropRef.current?.style.setProperty('z-index', '1040');
    }, [id])

    return (
        <>
            <div
                ref={modalRef}
                className={`modal ${data?.style ? data?.style : ''} ${isOpen && data ? "open" : ""}`}
            >
                <div className="modal-dialog">
                    <div
                        className="modal-content"
                    >
                        <span className="modal-close" onClick={onClose}></span>
                        <div className="modal-header">
                            {data?.icon && (
                                <img
                                    src={data?.icon}
                                    alt={`${data?.title} Icon`}
                                    width={data?.icon_width ? `${data?.icon_width}px` : "145px"}
                                />
                            )}
                            {data?.title && (
                                <h3 dangerouslySetInnerHTML={{ __html: data?.title }} />
                            )}
                        </div>
                        {data?.list && (
                            <div className="modal-list">
                                {data.list.map((item, index) => (
                                    <div key={index}>
                                        <b dangerouslySetInnerHTML={{ __html: item.title }} />
                                        <span dangerouslySetInnerHTML={{ __html: item.text }} />
                                    </div>
                                ))}
                            </div>
                        )}
                        {data?.text && (
                            <div className="modal-body" dangerouslySetInnerHTML={{ __html: data?.text }} />
                        )}
                        {data?.link && (
                            <a href={data?.link} target="_blank" rel="noreferrer" className="modal-pdf">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.159 60.682">
                                    <g>
                                        <g id="Calque_2">
                                            <g id="Eiffel">
                                                <g mix-blend-mode="multiply" opacity=".75">
                                                    <path d="M56.209,7.089c-5.267-5.267-13.806-5.267-19.073,0l-11.272,11.272c-5.448,5.448-4.944,14.129,0,19.073.828.828,1.744,1.503,2.711,2.057.9-.579,1.8-1.158,2.7-1.737.147-.675.271-1.496.297-2.433.021-.729-.021-1.386-.09-1.954-.296-.21-.584-.437-.85-.703-2.537-2.537-2.651-6.886,0-9.537.394-.394,11.033-11.033,11.272-11.272,2.63-2.63,6.907-2.63,9.537,0s2.63,6.907,0,9.537l-7.451,7.451c-.219,1.048-.42,2.581-.24,4.415.179,1.821.667,3.277,1.083,4.26.032-.032.071-.052.104-.085l11.272-11.272c5.267-5.267,5.267-13.806,0-19.073Z" fill="#cbcdce" strokeWidth="0" />
                                                    <path d="M37.784,25.513c-.828-.828-1.744-1.503-2.711-2.057-.779.383-1.558.766-2.337,1.149-.283.723-.59,1.755-.66,3.02-.041.747.009,1.407.09,1.954.296.21.584.437.85.703,2.537,2.537,2.651,6.886,0,9.537-.395.395-11.915,11.915-12.146,12.146-2.63,2.63-6.907,2.63-9.537,0-2.63-2.63-2.63-6.907,0-9.537l8.324-8.324c.32-.81.864-2.508.602-4.665-.231-1.907-.985-3.292-1.446-4.01-.032.032-.072.053-.104.085l-12.145,12.146c-5.267,5.267-5.267,13.806,0,19.073,5.267,5.267,13.806,5.267,19.073,0l12.146-12.146c5.347-5.347,5.055-14.019,0-19.073Z" fill="#cbcdce" strokeWidth="0" />
                                                </g>
                                                <path d="M53.593,3.95c-5.267-5.267-13.806-5.267-19.073,0l-11.272,11.272c-5.448,5.448-4.944,14.129,0,19.073.828.828,1.744,1.503,2.711,2.057.9-.579,1.8-1.158,2.7-1.737.147-.675.271-1.496.297-2.433.021-.729-.021-1.386-.09-1.954-.296-.21-.584-.437-.85-.703-2.537-2.537-2.651-6.886,0-9.537.394-.394,11.033-11.033,11.272-11.272,2.63-2.63,6.907-2.63,9.537,0,2.63,2.63,2.63,6.907,0,9.537l-7.451,7.451c-.219,1.048-.42,2.581-.24,4.415.179,1.821.667,3.277,1.083,4.26.032-.032.071-.052.104-.085l11.272-11.272c5.267-5.267,5.267-13.806,0-19.073Z" fill="#193686" strokeWidth="0" />
                                                <path d="M35.169,22.375c-.828-.828-1.744-1.503-2.711-2.057-.779.383-1.558.766-2.337,1.149-.283.723-.59,1.755-.66,3.02-.041.747.009,1.407.09,1.954.296.21.584.437.85.703,2.537,2.537,2.651,6.886,0,9.537-.395.395-11.915,11.915-12.146,12.146-2.63,2.63-6.907,2.63-9.537,0-2.63-2.63-2.63-6.907,0-9.537l8.324-8.324c.32-.81.864-2.508.602-4.665-.231-1.907-.985-3.292-1.446-4.01-.032.032-.072.053-.104.085l-12.145,12.146c-5.267,5.267-5.267,13.806,0,19.073,5.267,5.267,13.806,5.267,19.073,0l12.146-12.146c5.347-5.347,5.055-14.019,0-19.073Z" fill="#193686" strokeWidth="0" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div
                ref={modalBackdropRef}
                className={`modal-backdrop ${isOpen && data ? "open" : ""}`}
                onClick={onClose}
            />
        </>
    )
}