"use client"

import { useEffect, useState } from "react"
import { Modal } from "../components/modal/modal";
import { VideoModal } from "../components/video-modal/video-modal";

export const ModalProvider = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, [])

    if (!isMounted) return null;

    return (
        <>
            <Modal />
            <VideoModal />
        </>
    )
}