import './App.css';

import { Map } from './components/map/map';

function App() {
  return (
    <>
      <h1>
        Our experts keep Paris moving this summer
      </h1>
      <hr />
      <Map />
      <h2>
        Key facts about Otis in the Paris region:
      </h2>
      <div className='key-numbers'>
        <div>
          <b>~48,000</b>
          <span>units maintained by Otis</span>
        </div>
        <div>
          <b>600<sup>+</sup></b>
          <span>Field Technicians</span>
        </div>
        <div>
          <b>3,000<sup>+</sup></b>
          <span>units maintained by Otis <br />within secure perimeters</span>
        </div>
        <div>
          <b>115</b>
          <span>Otis branches & Service centers</span>
        </div>
        <div>
          <b>~35<small>%</small></b>
          <span>connected units</span>
        </div>
      </div>
    </>
  );
}

export default App;
