export const modalDatas = [
    {
        id: 'subway',
        style: 'gray',
        title: 'Subway / <br/>Railway stations',
        icon: './images/subway.svg',
        icon_width: 145,
        list: [
            {
                title: '~300',
                text: 'units maintained<br/>by Otis'
            }
        ],
        text: "Otis is committed to developing accessible solutions to support inclusive mobility in the Paris region. For example, we have placed high-contrast warning stripes at busy traffic areas across EOLE's 5 train stations for visually impaired riders. By developing an inclusive approach to project design we help to create positive change in our local communities and connect travelers to more Paris destinations than ever before."
    },
    {
        id: 'hotels',
        style: 'dark-blue',
        title: 'Hotels',
        icon: './images/hotel.svg',
        icon_width: 125,
        list: [
            {
                title: '1,100<sup>+</sup>',
                text: 'units maintained<br/>by Otis'
            },
            {
                title: '50<small>%</small>',
                text: 'connected units'
            }
        ],
        text: "Otis plays a significant role in the Paris region's hotel industry, providing services to 8 of the 12 prestigious palaces, luxury establishments and renowned international brands such as Hyatt Etoile and H4 Hotel Wyndham Paris Pleyel Resort. To promote optimal service for these buildings, many of which are located in secure perimeters, our service teams use connected technologies that enables real-time data insights and access to remote services."
    },
    {
        id: 'hospitals',
        style: 'dark-blue',
        title: 'Hospitals',
        icon: './images/hospital.svg',
        icon_width: 115,
        list: [
            {
                title: '2,800<sup>+</sup>',
                text: 'units maintained<br/>by Otis'
            },
            {
                title: '35<small>%</small>',
                text: 'connected units'
            }
        ],
        text: "Elevators play a critical role in healthcare facilities. Otis is present in many hospitals in the Paris region, including major public hospitals such as Pitié-Salpêtrière-Charles Fois, the largest in Europe. With an on-call team available in 95% of hospitals, we minimize shutdowns so that equipment is back in service as soon as possible."
    },
    {
        id: 'otisline',
        style: 'blue',
        title: 'OTISLINE®',
        icon: './images/otis.svg',
        icon_width: 110,
        text: "OTISLINE® is the Otis 24-Hour customer service center for emergencies and service calls."
    },
    {
        id: 'otis_clinic_center',
        style: 'blue',
        title: 'Otis Clinic Center',
        icon: './images/otis.svg',
        icon_width: 110,
        text: "The Clinic Center helps improve the quality of service provided to customers with access to technical support and expert advices. <br/>With an average of 800 successful remote interventions per month, the Clinic Center provides valuable support to the maintenance teams throughout France and keeps people moving."
    },
    {
        id: 'otis_epc',
        style: 'blue',
        title: 'Otis EPC',
        icon: './images/otis.svg',
        icon_width: 110,
        text: "The European Parts Center (EPC) serves Otis branches and distributors in 120 countries. We maintain a stock of key spares, including some specialized electronic components."
    },
    {
        id: 'parc_des_princes',
        style: 'pink',
        title: 'Parc des Princes',
        icon: './images/parc_des_princes.svg',
        icon_width: 125,
        list: [
            {
                title: '21',
                text: 'escalators & elevators <br/>maintained by Otis'
            },
            {
                title: '35<sup>+</sup><small>%</small>',
                text: 'connected units'
            },
            {
                title: '2',
                text: 'technicians on site <br/>per week'
            }
        ]
    },
    {
        id: 'roland_garros',
        style: 'pink',
        title: 'Roland-Garros',
        icon: './images/roland_garros.svg',
        icon_width: 125,
        list: [
            {
                title: '89',
                text: 'elevators <br/>maintained by Otis'
            },
            {
                title: '~65<small>%</small>',
                text: 'connected units'
            },
            {
                title: '8',
                text: 'technicians on site <br/>per week'
            }
        ],
        link: 'https://otiselevator.sharepoint.com/Shared%20Documents/Forms/AllItems.aspx?id=%2FShared%20Documents%2FRolandGarros%5FFactsheet%5FFINAL%2Epdf&parent=%2FShared%20Documents'
    },
    {
        id: 'village',
        style: 'pink',
        title: 'Athletes & <br/>Para Athletes Village',
        icon: './images/village.svg',
        icon_width: 95,
        list: [
            {
                title: '175<sup>+</sup>',
                text: 'elevators <br/>maintained by Otis'
            },
            {
                title: '55<sup>+</sup><small>%</small>',
                text: 'connected units'
            },
            {
                title: '14',
                text: 'technicians on site <br/>24/7 per week'
            }
        ],
        link: 'https://otiselevator.sharepoint.com/Shared%20Documents/Forms/AllItems.aspx?id=%2FShared%20Documents%2FAthletes%2DVillage%5FFactsheet%2DFINAL%2Epdf&parent=%2FShared%20Documents'
    },
    {
        id: 'eiffel_tower',
        style: 'pink',
        title: 'Eiffel tower',
        icon_width: 70,
        icon: './images/eiffel_tower.svg',
        list: [
            {
                title: '14',
                text: 'units maintained<br/> by Otis'
            },
            {
                title: '7',
                text: 'technicians on site <br/>24/7 per week'
            }
        ],
        link: 'https://www.otis.com/en/us/our-company/global-projects/project-showcase/eiffel-tower'
    },
    {
        id: 'grand_palais',
        style: 'pink',
        title: 'Grand Palais',
        icon: './images/grand_palais.svg',
        icon_width: 155,
        list: [
            {
                title: '25',
                text: 'elevators<br/> maintained by Otis'
            },
            {
                title: '4',
                text: 'technicians on site <br/>per week'
            }
        ],
        link: 'https://otiselevator.sharepoint.com/Shared%20Documents/Forms/AllItems.aspx?id=%2FShared%20Documents%2FGrand%20Palais%5FFactsheet%5FFINAL%2Epdf&parent=%2FShared%20Documents'
    },
    {
        id: 'u_arena',
        style: 'pink',
        title: 'U Arena',
        icon: './images/u_arena.svg',
        icon_width: 135,
        list: [
            {
                title: '21',
                text: 'escalators & elevators<br/> maintained by Otis'
            },
            {
                title: '65<sup>+</sup><small>%</small>',
                text: 'connected units'
            },
            {
                title: '8',
                text: 'technicians on site <br/>per week'
            }
        ]
    },
    {
        id: 'aquatic_center',
        style: 'pink',
        title: 'Aquatic Center',
        icon: './images/aquatic_center.svg',
        icon_width: 135,
        list: [
            {
                title: '5',
                text: 'elevators<br/> maintained by Otis'
            },
            {
                title: '100<small>%</small>',
                text: 'connected units'
            }
        ],
        link: 'https://otiselevator.sharepoint.com/Shared%20Documents/Forms/AllItems.aspx?id=%2FShared%20Documents%2FAquatic%5FCenter%5FFactsheet%5FFINAL%2Epdf&parent=%2FShared%20Documents'
    },
    {
        id: 'adidas_arena',
        style: 'pink',
        title: 'adidas arena',
        icon: './images/adidas_arena.svg',
        icon_width: 135,
        list: [
            {
                title: '22',
                text: 'escalators & elevators<br/> maintained by Otis'
            },
            {
                title: '~70<small>%</small>',
                text: 'connected units'
            },
            {
                title: '8',
                text: 'technicians on site <br/>per week'
            }
        ],
        link: 'https://otiselevator.sharepoint.com/Shared%20Documents/Forms/AllItems.aspx?id=%2FShared%20Documents%2FAdidas%5FArena%5FFactsheet%5FFINAL%2Epdf&parent=%2FShared%20Documents'
    }
];

export const videoModalDatas = [
    {
        id: 'stuck_passengers',
        title: 'Trapped passengers',
        link: './videos/OTIS_trapped_passengers.mp4'
    },
    {
        id: 'elevator_breakdown',
        title: 'Out of service elevator',
        link: './videos/OTIS_out_of_service_elevator.mp4'
    },
];
